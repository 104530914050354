var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.dataItem.inEdit
    ? _c("td", { class: _vm.className }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.dataItem.customer ? "" + _vm.dataItem.customer.name : ""
            ) +
            " "
        ),
      ])
    : _c(
        "td",
        [
          _c("dropdownlist", {
            attrs: {
              "data-items": _vm.customers,
              "default-item": _vm.defaultItem,
              "text-field": "name",
              "data-item-key": "id",
              value: _vm.value,
            },
            on: { change: _vm.change },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }