<template>
  <div>
    <h1>Accounts</h1>
    <Grid
      ref="grid"
      :data-items="result"
      :filterable="true"
      :filter="filter"
      @filterchange="filterChange"
      :edit-field="'inEdit'"
      :pageable="pageable"
      :take="take"
      :skip="skip"
      :page-size="pageSize"
      :total="total"
      :sortable="{ mode: 'multiple' }"
      :sort="sort"
      @sortchange="sortChangeHandler"
      @itemchange="itemChange"
      @dataStateChange="dataStateChange"
      :columns="columns"
      @pagechange="pageChange"
      :selected-field="selectedField"
      @rowclick="onRowClick"
    >
      <template v-slot:myTemplate="{ props }">
        <custom
          :data-item="props.dataItem"
          @edit="edit"
          @save="save"
          @remove="remove"
          @cancel="cancel"
        />
      </template>
      <template v-slot:custDropDownCell="{ props }">
        <custddcell
          :data-item="props.dataItem"
          :field="props.field"
          @change="(e) => custddChange(e, props.dataItem)"
        />
      </template>
      <template v-slot:myDropDownCell="{ props }">
        <ddcell
          :data-item="props.dataItem"
          :field="props.field"
          @change="(e) => ddChange(e, props.dataItem)"
        />
      </template>
      <template v-slot:mySharingCell="{ props }">
        <!-- <div>X</div> -->
        <sharingcell
          :data-item="props.dataItem"
          :field="props.field"
        />
      </template>
      <grid-toolbar>
        <v-btn 
          v-show="user.claims.user_role == 'super_user' || user.claims.user_role == 'customer_admin'" 
          :ripple="false"
          :elevation="0"
          class="
          font-weight-bold
          text-xs
          btn-default
          bg-gradient-default" 
          id="add_new_btn" 
          @click="insert"
        >
          Add new
        </v-btn>
        <v-btn
          v-if="hasItemsInEdit"
          :ripple="false"
          :elevation="0"
          class="
          font-weight-bold
          text-xs
          btn-default
          bg-gradient-danger"
          id="cancel_changes_btn"
          @click="cancelChanges"
        >
          Cancel current changes
        </v-btn>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="
          font-weight-bold
          text-xs
          btn-default
          bg-gradient-default"
          id="export_btn"
          @click="exportExcel"
        >
          Export to Excel
        </v-btn>
      </grid-toolbar>
      <grid-norecords> There is no data available custom </grid-norecords>
    </Grid>
  </div>
</template>

<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import { orderBy } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-data-query";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import CommandCell from "./CommandCell";
import DropDownCell from "./DropDownCell.vue";
import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
import Service from "@/services/Service.js";
import CustDropDownCellVue from "./CustDropDownCell.vue";
import SharingCell from "./SharingCell.vue";
Vue.use(VueToast);

export default {
  components: {
    Grid: Grid,
    "grid-toolbar": GridToolbar,
    "grid-norecords": GridNoRecords,
    custddcell: CustDropDownCellVue,
    ddcell: DropDownCell,
    custom: CommandCell,
    sharingcell: SharingCell
  },
  data: function() {
    return {
      filter: null,
      sort: null,
      pageable: { pageSizes: [10, 20, 50, 100] },
      gridData: [],
      skip: 0,
      take: 20,
      random: 1,
      pageSize: 20,
      updatedData: [],
      selectedID: 1,
      selectedField: "selected",
      editID: null,
      sort: [{ field: "name", dir: "asc" }],
    };
  },
  mounted() {
    document.title = "Accounts"
  },
  computed: {
    result: {
      get: function() {
        return filterBy(this.gridData, this.filter).slice(
          this.skip,
          this.take + this.skip
        );
      },
    },
    hasItemsInEdit() {
      return this.gridData.filter((p) => p.inEdit).length > 0;
    },
    total() {
      return this.gridData ? filterBy(this.gridData, this.filter).length : 0;
    },
    user: function() {
      return this.$store.getters.currentUser;
    },
    columns: function() {
      if(this.user.user_role.name == 'customer_admin' || this.user.user_role.name == 'super_user') {
        return [
          { field: "id", hidden: true, editable: false, title: "ID" },
          { field: "name" },
          { field: "email" },
          { field: "phone" },
          { field: "notes" },
          { field: "customer.name", title: "Customer", cell: "custDropDownCell" },
          { field: "contact.name", title: "Contact", cell: "myDropDownCell" },
          { field: "users", title: "Sharing", filterable: false, cell: "mySharingCell" },
          { cell: "myTemplate", filterable: false, width: "210px" },
        ]
      } else {
        return [
          { field: "id", hidden: true, editable: false, title: "ID" },
          { field: "name" },
          { field: "email" },
          { field: "phone" },
          { field: "notes" },
          { field: "customer.name", title: "Customer", cell: "custDropDownCell" },
          { field: "contact.name", title: "Contact", cell: "myDropDownCell" }
        ]
      }
    },
  },
  created: function() {
    this.getData();
  },
  methods: {
    filterChange: function(ev) {
      this.filter = ev.filter;
    },
    getData: function() {
      NProgress.start()
      Service.getAccounts()
        .then((response) => {
          // const a = response.data.map((c) => {
          //   c.date_joined = new Date(c.date_joined);
          //   return c;
          // });
          this.gridData = response.data;
          NProgress.done()
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
          NProgress.done()
        });
    },
    onRowClick(event) {
      this.selectedID = event.dataItem.id;
      console.log(`selected ${this.selectedID}`);
    },
    custddChange: function(e, dataItem) {
      console.log(`custddChange ${e}, data: ${dataItem}`);
      const updatedData = this.gridData.slice();
      const item = this.update(updatedData, dataItem);
      item.customer_id = e.target.value.id;
      item.customer = e.target.value;
      this.gridData = updatedData;
    },
    ddChange: function(e, dataItem) {
      console.log(`ddChange ${e}, data: ${dataItem}`);
      const updatedData = this.gridData.slice();
      const item = this.update(updatedData, dataItem);
      item.contact_id = e.target.value.id;
      item.contact = e.target.value;
      this.gridData = updatedData;
    },
    sortChangeHandler: function(e) {
      this.sort = e.sort;
      this.gridData = orderBy(this.gridData, this.sort);
    },
    dataStateChange: function(event) {
      // console.log("dataStateChange...");
    },
    pageChange(event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    itemChange: function(e) {
      if (e.dataItem.id) {
        let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
        let updated = Object.assign({}, this.gridData[index], {
          [e.field]: e.value,
        });
        this.gridData.splice(index, 1, updated);
      } else {
        e.dataItem[e.field] = e.value;
      }
    },
    insert() {
      const dataItem = { inEdit: true };
      const newAccounts = this.gridData.slice();
      newAccounts.unshift(dataItem);
      this.update(newAccounts, dataItem);
      this.gridData = newAccounts;
    },
    edit: function(e) {
      let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
      let updated = Object.assign({}, this.gridData[index], { inEdit: true });
      this.gridData.splice(index, 1, updated);
    },
    save: function(e) {
      let accountID = e.dataItem.id;
      let index = this.gridData.findIndex(
        (c) =>
          JSON.stringify({ ...c }) === JSON.stringify(e.dataItem) ||
          (accountID && c.id === accountID)
      );
      let item = this.gridData[index];
      let updated = Object.assign(this.update(this.gridData.slice(), item), {
        inEdit: undefined,
      });
      // upsert logic... base off id.. if undefined, then create... else update
      if (updated.id == undefined) {
        Service.createAccount(updated)
          .then((response) => {
            Vue.$toast.success(`Account created`, {
              position: "top-right",
              duration: 4000,
            });
            updated.id = response.data.id;

            this.gridData.splice(index, 1, updated);
          })
          .catch((error) => {
            Vue.$toast.error(`Error saving data`, { position: "top-right" });
            console.log(error);
          });
      } else {
        Service.updateAccount(updated.id, updated)
          .then((response) => {
            Vue.$toast.success(`Account updated`, {
              position: "top-right",
              duration: 4000,
            });
            this.gridData.splice(index, 1, updated);
          })
          .catch((error) => {
            Vue.$toast.error(`Error updating data`, { position: "top-right" });
            console.log(error);
          });
      }
    },
    update(data, item, remove) {
      let updated;
      let accountID = item.id;
      let index = data.findIndex(
        (c) =>
          JSON.stringify({ ...c }) === JSON.stringify(item) ||
          (accountID && c.id === accountID)
      );
      if (index >= 0) {
        updated = Object.assign({}, item);
        data[index] = updated;
      } else {
        let id = 0;
        updated = Object.assign({}, item, { id: id });
        data.unshift(updated);
        index = 0;
      }

      if (remove) {
        data = data.splice(index, 1);
      }
      return data[index];
    },
    cancel(e) {
      if (e.dataItem.id) {
        let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
        let updated = Object.assign(this.gridData[index], {
          inEdit: undefined,
        });
        this.gridData.splice(index, 1, updated);
      } else {
        let index = this.gridData.findIndex(
          (p) => JSON.stringify(e.dataItem) === JSON.stringify(p)
        );
        this.gridData.splice(index, 1);
      }
    },
    remove(e) {
      e.dataItem.inEdit = undefined;
      Service.deleteAccount(e.dataItem.id)
        .then((response) => {
          Vue.$toast.warning(`Account removed`, {
            position: "top-right",
            duration: 4000,
          });
          this.update(this.gridData, e.dataItem, true);
        })
        .catch((error) => {
          Vue.$toast.error(`Error removing data`, { position: "top-right" });
          console.log(error);
        });
    },
    cancelChanges() {
      let editedItems = this.gridData.filter((p) => p.inEdit === true);
      if (editedItems.length) {
        editedItems.forEach((item) => {
          item.inEdit = undefined;
        });
      }
    },
    exportExcel() {
      saveExcel({
        data: this.dataToExport(),
        fileName: "accounts.xlsx",
        columns: this.columns
      });
    },
    dataToExport() {
      if (this.filter) {
        return this.result;
      } else {
        return this.gridData;
      }
    }
  },
};
</script>

<style>
  .k-toolbar {
    display: block;
  }

  .k-toolbar #add_new_btn {
    float: left;
  }

  .k-toolbar #cancel_changes_btn {
    margin-left: 1%;
  }

  .k-toolbar #export_btn {
    float: right;
  }
</style>
