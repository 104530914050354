var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "td",
    [
      _c(
        "v-icon",
        {
          attrs: { disabled: !this.admin },
          on: {
            click: function ($event) {
              return _vm.show()
            },
          },
        },
        [_vm._v(" mdi-share-variant ")]
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "640" },
          model: {
            value: _vm.showShareDialog,
            callback: function ($$v) {
              _vm.showShareDialog = $$v
            },
            expression: "showShareDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(_vm._s(_vm.accountName) + " - Shared User Settings"),
              ]),
              _c(
                "v-card-text",
                [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "v-radio-group",
                        {
                          attrs: {
                            label: "Select with User or Email address",
                            row: "",
                          },
                          model: {
                            value: _vm.active,
                            callback: function ($$v) {
                              _vm.active = $$v
                            },
                            expression: "active",
                          },
                        },
                        [
                          _c("v-radio", {
                            attrs: { name: "active", label: "User", value: 0 },
                          }),
                          _c("v-radio", {
                            attrs: { name: "active", label: "Email", value: 1 },
                          }),
                        ],
                        1
                      ),
                      _vm.active == 0
                        ? _c("label", { attrs: { for: "userlist" } }, [
                            _vm._v("Share with User"),
                          ])
                        : _vm._e(),
                      _vm.active == 0
                        ? _c("v-autocomplete", {
                            attrs: {
                              items: _vm.users,
                              outlined: "",
                              "item-text": "name",
                              "item-value": "id",
                              dense: "",
                              chips: "",
                              "small-chips": "",
                              label: "Select User",
                            },
                            model: {
                              value: _vm.user,
                              callback: function ($$v) {
                                _vm.user = $$v
                              },
                              expression: "user",
                            },
                          })
                        : _vm._e(),
                      _vm.active == 1
                        ? _c("v-text-field", {
                            attrs: {
                              label: "",
                              placeholder: "Enter User's email",
                            },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          staticClass:
                            "font-weight-bold text-xs btn-success bg-gradient-success mb-2",
                          attrs: { ripple: false, elevation: 0 },
                          on: {
                            click: function ($event) {
                              return _vm.addShare()
                            },
                          },
                        },
                        [_vm._v(" Add ")]
                      ),
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.items,
                      "items-per-page": 5,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.controls",
                        fn: function (props) {
                          return [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mx-2",
                                attrs: {
                                  disabled: props.item.account_admin,
                                  icon: "",
                                  color: "red",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteItem(props.item)
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-delete")])],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "black", outlined: "" },
                      on: { click: _vm.close },
                    },
                    [_vm._v(" Close ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }