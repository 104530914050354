<template>
  <td v-if="!dataItem.inEdit" :class="className">
    {{ dataItem.customer ? `${dataItem.customer.name}` : "" }}
  </td>
  <td v-else>
    <dropdownlist
      :data-items="customers"
      :default-item="defaultItem"
      :text-field="'name'"
      :data-item-key="'id'"
      :value="value"
      @change="change"
    >
    </dropdownlist>
  </td>
</template>
<script>
import Service from "@/services/Service.js";
import { DropDownList } from '@progress/kendo-vue-dropdowns';
export default {
  components: {
    dropdownlist: DropDownList,
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  data: function () {
    return {
      customers: [],
      value: {},
      defaultItem: {
        name: "Select customer ...",
        id: 0,
      },
    };
  },
  created: function () {
    if(this.dataItem.customer) {
      this.value = {id: this.dataItem.customer.id, name: this.dataItem.customer.name}
    }
    this.getData();
  },
  methods: {
    getData: function () {
      Service.getCustomers()
        .then((response) => {
          this.customers = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    change(e) {
      this.value = {id: e.target.value.id, name: e.target.value.name};
      this.$emit("change", e, e.target.value);
    },
  },
};
</script>