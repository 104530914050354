<template>
<td v-if="!dataItem['inEdit']">
    <div>
        <v-btn
            elevation="0"
            height="32"
            class="
            font-weight-bold
            text-xs
            btn-default
            bg-gradient-primary
            "
            color="#5e72e4"
            @click="editHandler"
            id="account_edit"
        >
            Edit
        </v-btn>
        <v-btn
            elevation="0"
            height="32"
            class="
            font-weight-bold
            text-xs
            btn-default btn-outline-default
            shadow-none"
            color="transparent"
            id="account_remove"
            @click="removeHandler">
            Remove
        </v-btn>
    </div>
</td>
<td v-else>
        <v-btn
            elevation="0"
            height="32"
            class="
            font-weight-bold
            text-xs
            btn-default
            bg-gradient-primary
            "
            color="#5e72e4"
            id="account_add"
            @click="addUpdateHandler">
            {{dataItem.ProductID? 'Update' : 'Add'}}
        </v-btn>
        <v-btn
            elevation="0"
            height="32"
            class="
            font-weight-bold
            text-xs
            btn-default btn-outline-default
            shadow-none"
            color="transparent"
            id="account_discard"
            @click="cancelDiscardHandler">
            {{dataItem.ProductID? 'Cancel' : 'Discard'}}
        </v-btn>
    </td>
</template>
<script>
import Service from "@/services/Service.js";

export default {
    props: {
        field: String,
        dataItem: Object,
        format: String,
        className: String,
        columnIndex: Number,
        columnsCount: Number,
        rowType: String,
        level: Number,
        expanded: Boolean,
        editor: String
    },
    data: function () {
      return {
        admin: false,
      }
    },
    created: function() {
        // check to see if current user is account admin... if not, then disable edit/delete buttons
        let userId = this.user.id;
        let accountId = this.dataItem.id;
        // console.log(`on create... ${this.dataItem}`)
        Service.isUserAccountAdmin(userId, accountId)
        .then((response) => {
          this.admin = response.data.admin;
        })
        .catch((error) => {
          // Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
        });
    },
    computed: {
      user: function() {
        return this.$store.getters.currentUser;
      },
    },
    methods: {
        editHandler: function() {
            this.$emit('edit', {dataItem:this.dataItem});
        },
        removeHandler: function() {
            this.$emit('remove', {dataItem:this.dataItem});
        },
        addUpdateHandler: function() {
            this.$emit('save', {dataItem:this.dataItem});
        },
        cancelDiscardHandler: function() {
            this.$emit('cancel', {dataItem:this.dataItem});
        }
    }
}
</script>
<style scoped>
#account_edit {
    min-width: 0;
    width: 28px;
    float: left;
}
#account_remove {
    min-width: 0;
    width: 28px;
    margin-left:5%; 
}
#account_add {
    min-width: 0;
    width: 28px;
    float: left;
}
#account_discard {
    min-width: 0;
    width: 28px;
    margin-left:5%; 
}
</style>