<template>
  <td>
    <v-icon :disabled="!this.admin" @click="show()"> mdi-share-variant </v-icon>
    <!-- load array of acct ids (for the logged in user) and pass in here to enable or disable icon -->
    <!-- <v-text-field v-model="id"></v-text-field> -->
    <v-dialog v-model="showShareDialog" width="640">
      <v-card>
        <v-card-title>{{ accountName }} - Shared User Settings</v-card-title>
        <v-card-text>
          <div class="form-group">
            <v-radio-group
              label="Select with User or Email address"
              row
              v-model="active"
            >
              <v-radio name="active" label="User" :value="0"></v-radio>
              <v-radio name="active" label="Email" :value="1"></v-radio>
            </v-radio-group>
            <label v-if="active == 0" for="userlist">Share with User</label>
            <v-autocomplete
              v-if="active == 0"
              v-model="user"
              :items="users"
              outlined
              item-text="name"
              item-value="id"
              dense
              chips
              small-chips
              label="Select User"
            ></v-autocomplete>
            <!-- @change="userSelect()" -->
            <v-text-field
              v-if="active == 1"
              label=""
              v-model="email"
              placeholder="Enter User's email"
            ></v-text-field>
            <!-- <input v-if="active == 1" v-model="email" class="form-control form-control-sm" id="userlist" placeholder="Enter User Email"> -->
            <v-btn
              :ripple="false"
              :elevation="0"
              class="font-weight-bold text-xs btn-success bg-gradient-success mb-2"
              @click="addShare()"
            >
              Add
            </v-btn>
          </div>
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:item.controls="props">
              <v-btn
                :disabled="props.item.account_admin"
                class="mx-2"
                icon
                color="red"
                @click="deleteItem(props.item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" @click="close" outlined> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </td>
</template>

<script>
import Service from "@/services/Service.js";
import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";

Vue.use(VueToast);
export default {
  components: {},
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  data: function () {
    return {
      active: 0,
      email: "",
      users: [],
      user: null,
      showShareDialog: false,
      items: [],
      headers: [
        { text: "User ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Company", value: "customer.name" },
        { text: "Acct Admin", value: "account_admin" },
        { text: "Remove", value: "controls", sortable: false },
      ],
    };
  },
  computed: {
    admin: function () {
      var users = this.dataItem.users;
      if(this.current_user && this.current_user.user_role && this.current_user.user_role.name == "super_user") {
        return true;
      } else if (users && users.length > 0) {
        const result = users.find((user) => user.id == this.current_user.id);
        console.log(result);
        if (result) {
          return result.account_admin;
        } else {
          return false;
        }
      } else {
        return false;
      }

      // find our user and return the admin flag
    },
    accountName: function () {
      return this.dataItem.name;
    },
    current_user: function () {
      return this.$store.getters.currentUser;
    },
    id: function () {
      return this.dataItem.id;
    },
  },
  created: function () {
    this.getUsers();
    // let userId = this.user.id;
    // let accountId = this.dataItem.id;
    // console.log(`sharing cell create: user: ${userId}, account: ${accountId}`)
    // Service.isUserAccountAdmin(userId, accountId)
    //     .then((response) => {
    //       this.admin = response.data.admin;
    //     })
    //     .catch((error) => {
    //       // Vue.$toast.error(`Error loading data`, { position: "top-right" });
    //       console.log(error);
    //     });
  },
  methods: {
    addShare() {
      if (this.active == 0) {
        console.log(
          `add share for ${this.dataItem.name}, for user: ${this.user.name}, `
        );
        Service.shareAccountWithUser(this.user, this.dataItem.id)
          .then((response) => {
            console.log(response.data);
            //reload
            this.getData(this.dataItem.id);
          })
          .catch((error) => {
            // Vue.$toast.error(`Error loading data`, { position: "top-right" });
            console.log(error);
          });
      } else {
        console.log(
          `add share for ${this.dataItem.name}, for user with email: ${this.email}, `
        );
        Service.getEmailCheck(this.email)
          .then((response) => {
            console.log(response.data);
            if (response.data.found == "1") {
              // send request msg
              Vue.$toast.success(`Sharing Request Sent`, {
                position: "top-right",
              });
            } else {
              Vue.$toast.warning(`User not found`, { position: "top-right" });
            }
            // this.getData(this.dataItem.id)
          })
          .catch((error) => {
            Vue.$toast.error(`Error checking data`, { position: "top-right" });
            console.log(error);
          });
      }
    },
    deleteItem(data) {
      var accountId = this.dataItem.id;
      var userId = data.id;
      console.log(`delete user: ${userId} for account ${accountId}`);
      Service.removeAccountUserShare(userId, accountId)
        .then((response) => {
          Vue.$toast.warning(`User removed`, { position: "top-right" });
          console.log(response.data);
          //reload
          this.getData(accountId);
        })
        .catch((error) => {
          // Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
        });
    },
    show(ev) {
      this.showShareDialog = true;
      console.log(ev);
      // load shares
      var accountId = this.dataItem.id;
      this.getData(accountId);
    },
    close() {
      this.showShareDialog = false;
    },
    getData(accountId) {
      Service.getUserSharesByAccount(accountId)
        .then((response) => {
          this.items = response.data;
        })
        .catch((error) => {
          // Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
        });
    },
    getUsers() {
      Service.getUsers()
        .then((response) => {
          this.users = response.data;
        })
        .catch((error) => {
          // Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
        });
    },
  },
};
</script>

<style>
</style>