var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Accounts")]),
      _c(
        "Grid",
        {
          ref: "grid",
          attrs: {
            "data-items": _vm.result,
            filterable: true,
            filter: _vm.filter,
            "edit-field": "inEdit",
            pageable: _vm.pageable,
            take: _vm.take,
            skip: _vm.skip,
            "page-size": _vm.pageSize,
            total: _vm.total,
            sortable: { mode: "multiple" },
            sort: _vm.sort,
            columns: _vm.columns,
            "selected-field": _vm.selectedField,
          },
          on: {
            filterchange: _vm.filterChange,
            sortchange: _vm.sortChangeHandler,
            itemchange: _vm.itemChange,
            dataStateChange: _vm.dataStateChange,
            pagechange: _vm.pageChange,
            rowclick: _vm.onRowClick,
          },
          scopedSlots: _vm._u([
            {
              key: "myTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("custom", {
                    attrs: { "data-item": props.dataItem },
                    on: {
                      edit: _vm.edit,
                      save: _vm.save,
                      remove: _vm.remove,
                      cancel: _vm.cancel,
                    },
                  }),
                ]
              },
            },
            {
              key: "custDropDownCell",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("custddcell", {
                    attrs: { "data-item": props.dataItem, field: props.field },
                    on: {
                      change: function (e) {
                        return _vm.custddChange(e, props.dataItem)
                      },
                    },
                  }),
                ]
              },
            },
            {
              key: "myDropDownCell",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("ddcell", {
                    attrs: { "data-item": props.dataItem, field: props.field },
                    on: {
                      change: function (e) {
                        return _vm.ddChange(e, props.dataItem)
                      },
                    },
                  }),
                ]
              },
            },
            {
              key: "mySharingCell",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("sharingcell", {
                    attrs: { "data-item": props.dataItem, field: props.field },
                  }),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "grid-toolbar",
            [
              _c(
                "v-btn",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.user.claims.user_role == "super_user" ||
                        _vm.user.claims.user_role == "customer_admin",
                      expression:
                        "user.claims.user_role == 'super_user' || user.claims.user_role == 'customer_admin'",
                    },
                  ],
                  staticClass:
                    "\n        font-weight-bold\n        text-xs\n        btn-default\n        bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, id: "add_new_btn" },
                  on: { click: _vm.insert },
                },
                [_vm._v(" Add new ")]
              ),
              _vm.hasItemsInEdit
                ? _c(
                    "v-btn",
                    {
                      staticClass:
                        "\n        font-weight-bold\n        text-xs\n        btn-default\n        bg-gradient-danger",
                      attrs: {
                        ripple: false,
                        elevation: 0,
                        id: "cancel_changes_btn",
                      },
                      on: { click: _vm.cancelChanges },
                    },
                    [_vm._v(" Cancel current changes ")]
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass:
                    "\n        font-weight-bold\n        text-xs\n        btn-default\n        bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, id: "export_btn" },
                  on: { click: _vm.exportExcel },
                },
                [_vm._v(" Export to Excel ")]
              ),
            ],
            1
          ),
          _c("grid-norecords", [_vm._v(" There is no data available custom ")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }