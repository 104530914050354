<template>
  <td v-if="!dataItem.inEdit" :class="className">
    {{ dataItem.contact ? `${dataItem.contact.name}` : "" }}
  </td>
  <td v-else>
    <dropdownlist
      :data-items="contacts"
      :default-item="defaultItem"
      :text-field="'name'"
      :data-item-key="'id'"
      :value="value"
      @change="change"
    >
    </dropdownlist>
  </td>
</template>
<script>
import Service from "@/services/Service.js";
import { DropDownList } from '@progress/kendo-vue-dropdowns';
export default {
  components: {
    dropdownlist: DropDownList,
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String,
  },
  data: function () {
    return {
      contacts: [],
      value: {},
      defaultItem: {
        name: "Select contact ...",
        id: 0,
      },
    };
  },
  created: function () {
    // lookup up contacts
    console.log(`dataItem: ${this.dataItem}, field: ${this.field}`);
    if(this.dataItem.contact) {
      this.value = {id: this.dataItem.contact.id, name: this.dataItem.contact.name}
    }
    // console.log("ContactDropDownCell created...");
    this.getData();
  },
  methods: {
    getData: function () {
      Service.getContacts()
        .then((response) => {
          // console.log("getData call... set gridData");
          this.contacts = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    change(e) {
      this.value = {id: e.target.value.id, name: e.target.value.name};
      this.$emit("change", e, e.target.value);

    },
  },
};
</script>